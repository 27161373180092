
<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
  <div>
    <b-card title="Informations générales">
       <validation-observer ref="simpleRules">
        <b-form
          @submit.prevent
        >
        <b-row>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Nom"
              label-for="basicInput"
            >
             <validation-provider
              #default="{ errors }"
              name="Nom"
              rules="required"
            >
              <b-form-input
              required
                id="basicInput"
                v-model="form.label"
                :plaintext="readOnly"
                placeholder="Nom"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>

           <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Valeur"
              label-for="basicInput"
            >
             <validation-provider
              #default="{ errors }"
              name="Valeur"
              rules="required"
            >
              <b-form-input
              required
                id="basicInput"
                v-model="form.value"
                :plaintext="readOnly"
                placeholder="Nom"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      </validation-observer>

    </b-card>
  <b-button v-if="edition==false"
     @click="createSize"
    >
    Enregistrer
    </b-button>

    <b-button v-else
     @click="editSize"
    >
    Enregistrer
    </b-button>


  </div>
</b-overlay>
</template>

<script>
import { BButton,BFormInput, BOverlay, BForm, BRow, BCol, BFormGroup,BFormFile, BCard, BFormCheckbox, BFormTextarea,BCardText, BMedia, BImg} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import {APIRequest} from '@simb2s/senseye-sdk'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

export default {
  name: 'SizeForm',
  components: {
    BButton,
    BFormInput,
    BFormGroup,
    BCardText, 
    BMedia, 
    BImg,
    BRow,
    BCol,
    BFormFile,
    BCard,
    BFormCheckbox,
    BFormTextarea,
    BOverlay,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BForm
  },
  directives: {
    Ripple,
  },
  data() {
      return {
          currentBase64:null,
          file: null,
          edition:false,
          loading:true,
          form:{
            labek:"",
            value:''
          },
      }
  },
  props: ['size', 'readOnly'],
  methods:{
    async createSize()
    {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
        this.loading=true
        try {
          await APIRequest.create('sizes',this.form)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'La taille a bien été créée !',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({name:'sizes'})
        } catch (error) {
          this.loading=false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Une erreur est survenue.',
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          
        }
        }
      })
      
    },
    async editSize()
    {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
      this.loading=true
        try {
        
          await APIRequest.update('sizes',this.size.id,this.form)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'La taille a bien été modifiée !',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({name:'sizes'})
        } catch (error) {
          this.loading=false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Une erreur est survenue.',
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          
        }
        }
      })
      
    },

  },
  async mounted(){
    if(this.size!=null)
    {
      this.form=this.size
      this.edition=true
    }
    this.loading=false
  }

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>